<template>
  <div class="dataset-page">
    <!-- banner -->
    <div class="dataset-page_banner">
      <!-- title num -->
      <div class="data-list">
        <div class="data-item" v-for="(item, index) in titleList" :key="index">
          <div class="value">{{ item.value }}</div>
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>

    <!-- 内容区域重构 -->
    <div class="dataset-page_type container">
      <el-tabs v-model="typeTabsActiveName" @tab-click="handleTypeClick">
        <!-- 数据集类型板块 -->
        <el-tab-pane label="类型" name="类型" disabled> </el-tab-pane>
        <el-tab-pane 
          v-for="(item, index) in typeList"
          :key="index"
          :label="item.label"
          :name="item.value"
        >
          <div class="type_content">
            <!-- 左侧导航区域改为树形结构 -->
            <div class="nav-container">
              <el-tree
                class="dimension-tree"
                :data="dimensionTree"
                :props="treeProps"
                :default-expand-all="false"
                @node-click="handleTreeNodeClick"
                :highlight-current="true"
                :expand-on-click-node="true"
              >
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span>{{ node.label }}</span>
                  <!-- <span class="node-count" v-if="data.children">({{ data.children.length }})</span> -->
                </span>
              </el-tree>
            </div>

            <!-- 右侧内容区域 -->
            <div class="content-container">
              <!-- 子维度导航（当存在子维度时显示） -->
              <div v-if="currentNode && currentNode.children" class="sub-dimensions">
                <el-tag
                  v-for="sub in currentNode.children"
                  :key="sub.EnglishName"
                  @click="handleSubClick(sub)"
                  class="sub-dimension-tag"
                >
                  {{ sub.ChineseName }}
                </el-tag>
              </div>

              <!-- 维度介绍 -->
              <div v-if="currentNode" class="dimension-intro">
                <div class="intro-header">
                  <h3>{{ currentNode.ChineseName }}</h3>
                </div>
                <div class="intro-content">
                  <p>{{ currentNode.ChineseIntro }}</p>
                </div>
              </div>

              <!-- 数据集列表 -->
              <div class="dataset-list">
                <!-- 数据集 -->
                <div v-if="datasetList.length">
                  <div
                    class="item"
                    v-for="(datasetItem, j) in datasetList"
                    :key="j"
                    @click="jumpDetail(datasetItem)"
                  >
                    <div class="item-title">{{ datasetItem.name }}</div>
                    <!-- <div class="item-label">
                    <el-tag type="success">开源</el-tag>
                    <el-tag type="info">标签A</el-tag>
                  </div> -->
                    <div class="item-desc">
                      {{ datasetItem.intro }}
                    </div>
                    <div class="item-lang">
                      语言: {{ datasetItem.language }}
                    </div>
                    <div class="item-source">
                      来源: {{ datasetItem.source }}
                    </div>
                    <div class="item-bottom d-flex">
                      <div class="left">查看详情</div>
                      <div class="right d-flex">
                        <!-- <div class="d-flex view-w">
                          <i class="el-icon-view"></i>
                          <div class="value">2024</div>
                        </div> -->
                        <div class="d-flex view-w">
                          <i class="el-icon-time"></i>
                          <div class="value">{{ datasetItem.time }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else class="empty-text">持续建设中...</div>
              </div>
            </div>
          </div>
          <!-- 维度内容 -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import DimensionList from "@/assets/data/dimension/dimension.json";
import OriginDatasetList from "@/assets/data/dataset/dataset.json";

export default {
  data() {
    return {
      titleList: [
        { value: "118", name: "数据集数量", desc: "中文：48 英文：76" },
        { value: "320万", name: "测试用例数量", desc: "" },
        { value: "52", name: "维度", desc: "" },
        { value: "4", name: "领域", desc: "" },
      ],

      // typeList: ["全部", "代码", "机器人", "医疗", "金融", "硬件", "中医"],
      typeList: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "通用",
          value: "common",
        },

        {
          label: "中医",
          value: "med",
        },
        {
          label: "金融",
          value: "finance",
        },
        {
          label: "代码",
          value: "code",
        },
      ],
      treeProps: {
        label: 'ChineseName',
        children: 'children'
      },
      dimensionTree: [], // 树形维度数据
      currentNode: null, // 当前选中节点
      selectedDimensions: [], // 当前选中的维度路径
      dimensionList: DimensionList.dimensions, //维度列表
      datasetList: [], //对应维度的数据集
      originDatasetList: OriginDatasetList.dataset, //所有的数据集数据
      typeTabsActiveName: "all",
      dimensionTabsActvieName: "",
      dimensionIndex: 0, //维度下标,
      tabHeight: 550,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
      this.initData();
      this.calculateTabHeight();
      // 初始化时主动触发类型切换
      this.handleTypeClick();
  },
  updated() {
    this.calculateTabHeight();
  },
  methods: {
    // 初始化数据
    // initData() {
    //   this.dimensionList = [
    //     ...DimensionList.FIN,
    //     ...DimensionList.CTCMB,
    //     ...DimensionList.commonDataset,
    //     ...DimensionList.code,
    //   ];
    //   this.dimensionTabsActvieName = this.dimensionList[0].ChineseName;

    //   const name = this.dimensionList[0].EnglishName;
    //   this.datasetList = this.originDatasetList.filter(
    //     (item) => item.dimension === name
    //   );
    // },
    initData() {
      this.originDatasetList = OriginDatasetList.dataset;
    },
    handleDimClick(tab) {
      const index = tab.index;
      this.dimensionIndex = index;
      this.getData();
    },
    // 构建树形结构的新方法
    buildTree(items, parentId = null) {
      return items
        .filter(item => item.parent_id === parentId)
        .map(item => ({
          ...item,
          children: this.buildTree(items, item.id)
        }));
    },

    // 处理类型切换
    handleTypeClick() {
      const type = this.typeTabsActiveName;
      let dimensions = [];
      
      switch(type) {
        case 'med':
          dimensions = this.buildTree(DimensionList.CTCMB);
          break;
        case 'finance':
          dimensions = this.buildTree(DimensionList.FIN);
          break;
        case 'code':
          dimensions = this.buildTree(DimensionList.code);
          break;
        case 'common':
          dimensions = this.buildTree(DimensionList.commonDataset);
          break;
        default: // all类型
          dimensions = [
            ...this.buildTree(DimensionList.commonDataset),
            ...this.buildTree(DimensionList.CTCMB),
            ...this.buildTree(DimensionList.FIN),
            ...this.buildTree(DimensionList.code)
          ];
      }

      this.dimensionTree = dimensions;
      
      // 默认选中第一个节点并加载数据
      if (this.dimensionTree.length > 0) {
        this.handleTreeNodeClick(this.dimensionTree[0]);
      }
    },

    // 处理树节点点击
    handleTreeNodeClick(node) {
      this.currentNode = node;
      this.selectedDimensions = this.getDimensionPath(node);

      // 收集所有相关维度（包括当前节点及其所有子节点）
      const allDimensions = this.collectAllDimensions(node);
      
      // 根据所有维度筛选数据集
      const filteredDatasetList = this.originDatasetList.filter(item =>
        allDimensions.includes(item.dimension)
      );

      // 去除重复数据集
      this.datasetList = this.removeDuplicates(filteredDatasetList);
    },
    // 如果不想要显示子维度的数据集，就用下面这个函数
    // handleTreeNodeClick(node) {
    //   this.currentNode = node;
    //   this.selectedDimensions = this.getDimensionPath(node);
      
    //   // 直接使用当前节点的英文名进行筛选
    //   this.datasetList = this.originDatasetList.filter(
    //     item => item.dimension === node.EnglishName
    //   );
    // },

    // 处理子维度点击
    handleSubClick(sub) {
      this.handleTreeNodeClick(sub);
    },

    // 辅助方法：获取维度路径
    getDimensionPath(node) {
      const path = [];
      let current = node;
      while (current) {
        path.unshift(current.ChineseName);
        current = current.parent;
      }
      return path;
    },

    // 辅助方法：收集所有子维度
    collectAllDimensions(node) {
      const dimensions = [];
      const queue = [node];
      while (queue.length > 0) {
        const current = queue.shift();
        dimensions.push(current.EnglishName);  // 收集当前维度
        if (current.children) {
          queue.push(...current.children);  // 收集所有子维度
        }
      }
      return dimensions;
    },
    // 辅助方法：去除重复数据集
    removeDuplicates(datasetList) {
      const uniqueMap = new Map();
      datasetList.forEach(item => {
        // 假设 'name' 是数据集的唯一标识符
        if (!uniqueMap.has(item.name)) {
          uniqueMap.set(item.name, item);
        }
      });
      return Array.from(uniqueMap.values());
    },
    getData() {
      // 获取数据集列表逻辑
      const value = this.typeTabsActiveName;
      if (value === "med") {
        this.dimensionList = DimensionList.CTCMB;
      } else if (value === "common") {
        this.dimensionList = DimensionList.commonDataset;
      } else if (value === "finance") {
        this.dimensionList = DimensionList.FIN;
      } else if (value === "code") {
        this.dimensionList = DimensionList.code;
      } else {
        this.dimensionList = [
          ...DimensionList.FIN,
          ...DimensionList.CTCMB,
          ...DimensionList.commonDataset,
          ...DimensionList.code,
        ];
      }
      this.dimensionTabsActvieName =
        this.dimensionList[this.dimensionIndex].ChineseName;

      // 获取数据集列表逻辑
      const name = this.dimensionList[this.dimensionIndex].EnglishName;
      // 匹配当前选择的维度中的EnglishName为数据集的维度dimension名称
      this.datasetList = this.originDatasetList.filter(
        (item) => item.dimension === name
      );
      this.calculateTabHeight();
    },
    jumpDetail(item) {
      console.log("item.", item);
      this.$router.push({
        path: "/dataset-detail",
        query: {
          name: item.name,
        },
      });
    },
    // 计算导航页高度
    calculateTabHeight() {
      const height = 250 + 225*this.datasetList.length;
      this.tabHeight = height>550? height:550;
      console.log('this.tabHeight', this.tabHeight)
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item.is-disabled {
  font-weight: bold;
  font-size: 0.95rem;
  color: #4e5969;
}

::v-deep .el-tabs__item{
  font-size: 0.9rem;
}

::v-deep .el-tabs__item.is-disabled:after {
  content: "";
  position: absolute;
  left: auto;
  top: auto;
  bottom: 10px;
  right: 0;
  height: 52%;
  width: 1px;
  background-color: #ccc;
}

::v-deep .el-tabs--left .el-tabs__header.is-left {
  width: 160px;
}

.el-tabs--left{
  width: 100%;
  text-align: left;
}

/* 新增树形导航样式 */
.nav-container {
  width: 270px;
  float: left;
  margin-right: 20px;
  border-right: 1px solid #e6e6e6;
  height: calc(100vh - 300px);
  overflow-y: auto;

  .dimension-tree {
    ::v-deep .el-tree-node__content {
      height: 40px;
      transition: all 0.3s;
      
      &:hover {
        background: #f5f7fa;
      }
    }

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.85rem;

      .node-count {
        color: #909399;
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }
}

/* 调整原有数据集项样式 */ 
/* 数据集项容器 */
.dataset-list {
  display: flex;
  flex-direction: column; /* 垂直排列每个数据集项 */
  gap: 16px; /* 数据集项之间的间距 */
}

.item {
  // padding: 16px;
  padding: 0.9rem;
  border-radius: 8px;
  border: 2px solid #e5e6eb;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  .item-title {
    height: 28px;
    font-weight: 500;
    font-size: 1.2rem;
    color: #1d2129;
    line-height: 28px;
    margin-bottom: 8px;
  }
  .item-label {
    .el-tag {
      margin-right: 16px;
    }
  }
  .item-desc {
    font-weight: 400;
    font-size: 0.9rem;
    color: #4e5969;
    text-align: left;
    line-height: 1.2;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .item-lang,
  .item-source {
    height: 24px;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #86909c;
    margin-bottom: 10px;
  }
  .item-bottom {
    width: 100%;
    margin-top: 16px;
    justify-content: space-between;
    font-size: 0.8rem;
    color: #86909c;
    .left {
      height: 22px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 0.8rem;
      color: #165dff;
      line-height: 22px;
      cursor: pointer;
    }
    .right {
      i {
        margin-right: 12px;
      }
      .view-w {
        margin-left: 36px;
      }
    }
  }
}
.item:hover {
  border: 1px solid #165dff;
}
.empty-text {
  font-size: 18px;
  color: #5f6a7a;
  text-align: center;
  margin-top: 50px;
}


.content-container {
  margin-left: 280px;
  
  .sub-dimensions {
    margin-bottom: 20px;
    
    .sub-dimension-tag {
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 1rem;

      &:hover {
        background: #409EFF;
        color: white;
      }
    }
  }

  .dimension-intro {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;

    .intro-header {
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 10px;
      padding-bottom: 10px;

      h3 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #333;
      }
    }

    .intro-content {
      p {
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
      }
    }
  }
}
.dataset-page {
  .dataset-page_banner {
    background: url("~@/assets/images/dataset/header_bg.png");
    height: 346px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;

    .data-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 70px;
    }

    .data-item {
      color: #1d2129;
      flex: 1 0;
      .value {
        font-family: Open Sans, Open Sans;
        font-weight: 800;
        font-size: 58px;
        height: 56px;
        line-height: 56px;
        margin-bottom: 20px;
      }
      .name {
        font-weight: bold;
        font-size: 0.9rem;
        height: 28px;
        line-height: 28px;
        color: #4e5969;
      }
      .desc {
        font-weight: 400;
        font-size: 0.8rem;
        color: #1d2129;
        height: 22px;
      }
    }
    .data-item:not(:last-child) {
      border-right: 1px solid #999;
    }
  }

  .dataset-page_type {
    width: 100%;
    margin-bottom: 32px;

    .type_content {
      .content-box {
        .header {
          // height: 108px;
          background: #0e42d2;
          background: url("~@/assets/images/dataset/title_bg.png");
          background-size: cover;
          background-position: center;
          box-shadow: inset 0px -1px 0px 0px #e5e6e8;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #e5e6eb;
          text-align: left;
          padding: 16px 16px;
          margin-bottom: 16px;
          border-radius: 8px;

          .title {
            height: 28px;
            font-weight: 500;
            font-size: 20px;
            color: #ffffff;
            line-height: 28px;
            margin-bottom: 10px;
          }

          .desc {
            height: 22px;
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
            line-height: 22px;
          }
        }
    }
    .left-tab {
    }
    }
  }
}
</style>
